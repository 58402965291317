import { lazy } from "react";

const Login = lazy(() => import("containers/Login"));
const Register = lazy(() => import("containers/Register"));
const Dashboard = lazy(() => import("containers/Dashboard"));
const Invoice = lazy(() => import("containers/Invoice"));
const InvoiceList = lazy(() => import("containers/Invoice/InvoiceList"));
const AddInvoice = lazy(() => import("containers/Invoice/AddInvoice"));
const InvoiceDetails = lazy(() => import("containers/Invoice/InvoiceDetails"));
const EditInvoice = lazy(() => import("containers/Invoice/EditInvoice"));
const Users = lazy(() => import("containers/Users"));
const Customers = lazy(() => import("containers/Customers"));
const Products = lazy(() => import("containers/Products"));
const Profile = lazy(() => import("containers/Profile"));
const AssetManagement = lazy(() => import("containers/AssetManagement"));
const RealEstate = lazy(() => import("containers/AssetManagement/RealEstate"));
const Vehicle = lazy(() => import("containers/AssetManagement/Vehicle"));
const Cash = lazy(() => import("containers/AssetManagement/Cash"));
const Cheque = lazy(() => import("containers/AssetManagement/Cheque"));
const Credit = lazy(() => import("containers/AssetManagement/Credit"));
const Stock = lazy(() => import("containers/AssetManagement/Stock"));
const BankAccount = lazy(() => import("containers/BankAccount"));
const CreditDetails = lazy(() =>
    import("containers/AssetManagement/Credit/CreditDetails")
);
const VehicleDetails = lazy(() =>
    import("containers/AssetManagement/Vehicle/VehicleDetails")
);
const RealEstateDetails = lazy(() =>
    import("containers/AssetManagement/RealEstate/RealEstateDetails")
);
const Companies = lazy(() => import("containers/Companies"));
const Company = lazy(() => import("containers/Companies/Company"));
const BulkCase = lazy(() => import("containers/Companies/BulkCase"));
const CompanyCase = lazy(() => import("containers/Companies/CompanyCase"));
const CreateFirstCompany = lazy(() => import("containers/CreateFirstCompany"));

const Components = {
    Login: <Login />,
    Register: <Register />,
    Dashboard: <Dashboard />,
    Invoice: <Invoice />,
    InvoiceList: <InvoiceList />,
    AddInvoice: <AddInvoice />,
    InvoiceDetails: <InvoiceDetails />,
    EditInvoice: <EditInvoice />,
    Users: <Users />,
    Customers: <Customers />,
    Products: <Products />,
    Profile: <Profile />,
    //Asset need to be change when Asset page is ready
    Assets: <Cash />,
    RealEstates: <RealEstate />,
    Vehicles: <Vehicle />,
    Cash: <Cash />,
    Cheques: <Cheque />,
    Credits: <Credit />,
    Stocks: <Stock />,
    BankAccounts: <BankAccount />,
    CreditDetails: <CreditDetails />,
    VehicleDetails: <VehicleDetails />,
    RealEstateDetails: <RealEstateDetails />,
    Companies: <Companies />,
    Company: <Company />,
    BulkCase: <BulkCase />,
    Case: <CompanyCase />,
    CreateFirstCompany: <CreateFirstCompany />,
};

export default Components;
