import "./index.css";
import AppRouter from "routes";
import {
  clearTokens,
  logout,
  updateAuthRefreshToken,
  updateAuthToken,
} from "store/auth";
import { UserService } from "services";
import {
  updateUser,
  updateCompany,
  userModifiedData,
  _refreshState,
} from "store/user";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import ThemeCustomization from "themes";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingPage from "components/UI/LoadingPage";

const userService = new UserService();

const App = () => {
  const { token, refreshToken: __refreshToken } = useSelector(
    (state) => state.auth
  );
  const { user, refreshState } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getAuthData = async () => {
    try {
      setLoading(true);
      if (!token) {
        return dispatch(logout());
      }

      let token_data = await userService.verifyToken(token);

      const { data } = token_data;

      /**
       * * If token is not verified, try to verify refresh token.
       */
      if (!data.user.company_id) {
        if (!__refreshToken) {
          return dispatch(logout());
        }
        const refresh = await onRefreshToken();
        if (!refresh) {
          return dispatch(logout());
        }
      }

      /**
       * * If token is verified, get user data.
       */

      const user_data = await userService.getUserById(data.user.id);

      const { user, company } = user_data.data;

      // set user data.
      dispatch(updateUser({ user }));
      // set company data.
      dispatch(updateCompany({ company }));
    } catch (error) {
      console.log(error);
      dispatch(logout());
    } finally {
      setLoading(false);
    }

    /**
     * *
     */
  };

  const onRefreshToken = async () => {
    const _refreshToken = await userService.refreshToken(__refreshToken);

    /**
     * * Logout if token cannot refreshed.
     */

    if (_refreshToken.status == 0) {
      return false;
    }

    /**
     * * Set new tokens
     */

    const { token: newToken, refreshToken: newRefreshToken } =
      _refreshToken.data;

    let token_data = await userService.verifyToken(newToken);

    const { data } = token_data;
    const user_data = await userService.getUserById(data.user.id);

    const { user, company } = user_data.data;

    // set user data.
    dispatch(updateUser({ user }));
    // set company data.
    dispatch(updateCompany({ company }));
    dispatch(clearTokens());
    dispatch(updateAuthToken({ token: newToken }));
    dispatch(updateAuthRefreshToken({ token: newRefreshToken }));

    return { newToken };
  };

  useEffect(() => {
    if (!token) return;
    getAuthData(token);
  }, []);

  useEffect(() => {
    if (refreshState) {
      if (!token) return;
      getAuthData(token);
      return dispatch(_refreshState({ refresh: false }));
    }
  }, [refreshState]);
  return (
    <LoadingPage loading={loading}>
      <ThemeCustomization>
        <AppRouter />
      </ThemeCustomization>
    </LoadingPage>
  );
};

export default App;
