import { createSlice } from "@reduxjs/toolkit";
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
} from "utils/helpers";
import { userModifiedData, setUserInitialState } from "../user";
import { UserService } from "services";

const userService = new UserService();
const Auth = createSlice({
  name: "auth",
  initialState: {
    token: getLocalStorage("token") || null,
    refreshToken: getLocalStorage("refreshToken") || null,
  },
  reducers: {
    authModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
  },
});

export const { authModifiedData } = Auth.actions;
export default Auth.reducer;

export const login =
  ({ token, user, refreshToken }) =>
  (dispatch) => {
    setLocalStorage("token", token);
    setLocalStorage("refreshToken", refreshToken);
    dispatch(authModifiedData({ name: "token", data: token }));

    dispatch(authModifiedData({ name: "refreshToken", data: refreshToken }));

    dispatch(userModifiedData({ name: "user", data: user }));
    dispatch(userModifiedData({ name: "isAuth", data: true }));
  };

export const register =
  ({ token, user, refreshToken }) =>
  (dispatch) => {
    setLocalStorage("token", token);
    setLocalStorage("refreshToken", refreshToken);
    dispatch(authModifiedData({ name: "token", data: token }));

    dispatch(authModifiedData({ name: "refreshToken", data: refreshToken }));

    dispatch(userModifiedData({ name: "user", data: user }));
    dispatch(userModifiedData({ name: "isAuth", data: true }));
  };

export const logout = () => (dispatch) => {
  removeLocalStorage("token");
  dispatch(authModifiedData({ name: "token", data: null }));
  dispatch(setUserInitialState());
};
export const updateToken = (token) => (dispatch) => {
  setLocalStorage("token", token);
  dispatch(authModifiedData({ name: "token", data: token }));
};

export const updateAuthToken =
  ({ token }) =>
  (dispatch) => {
    setLocalStorage("token", token);
    dispatch(authModifiedData({ name: "token", data: token }));
  };

export const updateAuthRefreshToken =
  ({ token }) =>
  (dispatch) => {
    setLocalStorage("refreshToken", token);
    dispatch(authModifiedData({ name: "refreshToken", data: token }));
  };

export const clearTokens = () => (dispatch) => {
  removeLocalStorage("token");
  removeLocalStorage("refreshToken");
  dispatch(authModifiedData({ name: "token", data: null }));
  dispatch(authModifiedData({ name: "refreshToken", data: null }));
};
