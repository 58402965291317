import { enqueueSnackbar } from "notistack";

export const _openSnackbar = (message, variant) => {
    console.log("HERE!!");
    enqueueSnackbar(message, {
        variant: variant || "default",
        anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        },
        autoHideDuration: 3000,
    });
};

export const checkPermission = (permArr, role) => {
    return permArr.includes(role);
};

export const generateColorByName = (name) => {
    let hash = 0;
    for (let i = 0; i < name?.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff;
        color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
};
